.workList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.workList-text {
    width: 65%;
}

.workList-title {
    font-size: 28pt;
    font-weight: normal;
}

.workList-description {
    font-size: 16pt;
    margin: 30px 0px;
}

.workList-list {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (max-width: 480px) {
    .workList {
        margin-top: 10%;
        padding: 10%;
        text-align: left;
    }
    .workList-text {
        width: 100%;
    }
    .workList-emoji {
        display: none;
    }
}