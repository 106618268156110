.work {
    width: 40vh;
    height: 35vh;
    margin: 20px 10px;
    border: 2px solid black;
    box-shadow: 0px 0px;
    overflow: hidden;
    transition: 0.3s ease;
}

.work-img {
    width: 101%;
    /* transition: all 10s ease; */
}

.work:hover {
    /* transform: translateY(-65%); */
    /* ADD PLAY GIF */
    transform: translateX(-3px) translateY(-3px);
    box-shadow: 10px 10px;
}

.work-browser {
    height: 20px;
    background-color: #F6F6F6;
    border-bottom: 2px solid black;
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 2;
}

.work-circle-1 {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin-left: 10px;
    background-color: #FF2D55;
}

.work-circle-2 {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin-left: 10px;
    background-color: #FFCC00;
}

.work-circle-3 {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin-left: 10px;
    background-color: #34C759;
}