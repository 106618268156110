:root {
    --primary: black;
    --secondary: white;
}

.btn--primary--outline {
    border: 2px solid var(--primary);
    background: var(--secondary);
    margin-top: 32px;
    margin-bottom: 32px;
    position: absolute;
    cursor: pointer;
    box-shadow: 0px 0px;
    transition: 0.3s ease;
}


/* HOVER ANIMATIONS */

.btn--primary--outline:hover {
    transform: translateX(-3px) translateY(-3px);
    box-shadow: 9px 9px;
}


/* -- BUTTON SIZES -- */

.btn--medium {
    padding: 15px 30px;
    font-size: 20px;
}

.btn--large {
    padding: 20px 40px;
    font-size: 20px;
}